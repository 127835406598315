<template>
    <div class="box">
        <div class="border">
            <el-form :inline="true" :model="data">
                <el-form-item label="您的称呼：">
                    <el-input v-model="data.name" class="name" placeholder="请输入您的姓名"></el-input>
                </el-form-item>
                <el-form-item label="您的电话：">
                    <el-input v-model="data.mobile" class="tel" placeholder="请输入您的电话"></el-input>
                </el-form-item>
                <el-form-item label="您想说的话：">
                    <el-input v-model="data.content" class="message" type="textarea" placeholder="请输入您的留言" rows="4"></el-input>
                </el-form-item>
            </el-form>
            <div class="bb">
                <el-button class="btn" type="primary" @click="submit">{{btntext}}</el-button>
                <div class="pointer"></div>
            </div>
        </div>
    </div>
</template>

<script>
import request from '@/utils/request'
import { Message } from 'element-ui'
export default {
    name:"Message",
    props:{
        btntext:{
            type: String,
            default: '免费咨询'
        }
    },
    data(){
        return{
            data:{
                name:'',
                mobile:'',
                content:''
            }
        }
    },
    methods:{
        submit(){
            if(this.data.name == ''){
                Message({
                    message: '请输入您的称呼',
                    type: 'warning',
                    duration: 5 * 1000
                })
                return
            }
            if(this.data.mobile == ''){
                Message({
                    message: '请输入您的电话',
                    type: 'warning',
                    duration: 5 * 1000
                })
                return
            }
            request.post('/in/website/leaveword',this.data).then(() => {
                this.data.name = ''
                this.data.mobile = ''
                this.content = ''
                Message({
                    message: '感谢您的留言',
                    type: 'success',
                    duration: 5 * 1000
                })
            })
        }
    }
}
</script>

<style lang="less" scoped>
.box{
    width: 1190px;
    // height: 290px;
    background-color: #ffffff;
    border-radius: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    .border{
        border-radius: 10px;
        width: 1160px;
        // height: 270px;
        border: 1px solid #FF7B00;
        padding-left: 56px;
        padding-top: 25px;
        padding-bottom: 15px;
        margin-left: 9px;
        margin-right: 9px;
        .bb{
            text-align:center;
            position:relative;
            margin-top: 30px;
            padding-right: 34px;
        }
    }
    .name{
        width: 430px;
        height: 40px;
        border: 1px solid #eeeeee;
        border-radius: 4px;
    }
    .tel{
        width: 353px;
        height: 40px;
        border: 1px solid #eeeeee;
        border-radius: 4px;
    }
    .message{
        display: inline-block;
        width: 895px;
        height: 93px;
        border: 1px solid #eeeeee;
        border-radius: 4px;
    }
    .btn{
        width: 220px;
        background: linear-gradient(91deg,#e94220 0%, #ff7b00 100%);
        box-shadow: 0px 4px 4px 0px rgba(255,123,0,0.25); 
        border-radius: 40px;
        border-color: unset;
        font-size: 22px;
    }
    /* 动画代码 */
    @keyframes pointer {
        from {top: -5px}
        to {top: 2px}
    }
    .pointer{
        display: inline-block;
        width: 67px;
        height: 67px;
        background-image: url(../assets/pointer.png);
        background-size: contain;
        background-position: center;
        position: absolute;
        right: calc(50% - 100px);
        animation-name: pointer;
        animation-duration: 0.5s;
        animation-iteration-count: infinite;
    }
}
</style>
<style lang="less">
.box{
    .el-form-item__label{
        min-width: 100px;
        font-size: 16px;
        color: #333333;
        text-align: right;
        padding-right: 0;
    }
    .el-input__inner{
        background: #f9f9f9;
    }
    .el-textarea__inner{
        resize: none; 
        background: #f9f9f9;
     }
}
</style>